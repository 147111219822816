import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faLifeRing,
  faPhone,
  faExternalLinkAlt,
  faHome,
  faChevronLeft,
  faChevronRight, faSearch,
} from "@fortawesome/free-solid-svg-icons"

const basicTemplate = props => {
  const { pageContext } = props
  const { pageContent, title, articlePath } = pageContext

  return (

    <Layout container={false} isDark={true} hasDarkColorScheme={"low-contrast"}>
      <SEO title={"Support › " + title} />

      <div className="container-fluid hero-big hero" style={{ height: "250px", backgroundPosition: "top center" }}
           id="support">
        <div className="text-center">
          <br />
          <br />
          <h1>Support</h1>
        </div>
      </div>


      <div className="container" style={{ paddingBottom: "95px" }}>

        <a name="section0"></a>

        <div className="breadcrumb">
          <div >
            <Link to="/support"><FontAwesomeIcon icon={faChevronLeft} /> Support</Link>
            &nbsp;
            /
            &nbsp;<a className="muted">{title}</a>
          </div>
          <a href={"/support/search/"}><FontAwesomeIcon icon={faSearch} /> Suchen</a>
        </div>


        <div className="has-sidenav">
          <nav>
            {pageContent.map((data, index) => {

              return <li key={`content_item_${index}`}><a href={`#section${index}`}>{data.item}</a></li>
            })}

          </nav>


          <div class="text">


            {pageContent.map((data, index) => {
              return <>
                <a name={`section${index}`}></a>
                <h2 key={`content_item_${index}`}>{data.item}</h2>
                {data.tags.map((div, index) => {

                  // TODO: This line should NOT be necessary
                  if (div.src == undefined && div.tag == "img")
                    div.src = div.href

                  if (div.tag == "p")
                    return <p>{div.value}</p>
                  else if (div.tag == "ul")
                    return <ul>{div.list.map(li => <li>{li}</li>)}</ul>
                  else if (div.tag == "img")
                    if (div.src == undefined)
                      return <div className={"alert alert-danger"}>Konnte Bild nicht laden {JSON.stringify(div)}.</div>
                    else
                      return <img
                        style={div.width == undefined ? { maxWidth: "850px" } : { maxWidth: div.width + "px" }}
                        src={require("../images/support" + articlePath + "/" + div.src)} />
                  else if (div.tag == "a")
                    return <a href={div.href} target="_blank" className="btn btn-primary-outline mb-2">
                      <FontAwesomeIcon icon={faExternalLinkAlt}></FontAwesomeIcon>&nbsp;
                      {div.value}
                    </a>

                })}

              </>
            })}


            <div className="support-footer">
              <h1 className="text-center">War dieser Artikel hilfreich?</h1>
              <a href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;support%40%7A%69%65%67%65%6E%68%61%67%65%6C%2E%63%6F%6D" 
              className="btn btn-primary-outline">
                <FontAwesomeIcon icon={faLifeRing}></FontAwesomeIcon>&nbsp;
                Kontaktieren Sie uns
              </a>
            </div>

          </div>

        </div>
      </div>
    </Layout>

  )
}
export default basicTemplate
